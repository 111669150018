import * as React from "react"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReCAPTCHA from "react-google-recaptcha"
import { trackPromise, usePromiseTracker } from "react-promise-tracker"

import { TagManagerContext } from "../../core/contexts/tagManager"
import { ApiContext } from "../../core/contexts/api"

import ElevatedButton from "../buttons/elevatedButton"
import Modal from "../modal/modal"

import "./subscribeForm.scss"

const SubscribeForm = () => {
  const tagManager = React.useContext(TagManagerContext)
  const api = React.useContext(ApiContext)
  const recaptchaRef = React.useRef<any>(null)

  const [open, setOpen] = React.useState(false)
  const handleSubscribeModalClose = () => setOpen(!open)

  const [dialogState, setDialogState] = React.useState("close")
  const [dialogMessage, setDialogMessage] = React.useState("")
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null)
  const [emailAddress, setEmailAddress] = React.useState("")
  const { promiseInProgress } = usePromiseTracker()

  React.useEffect(() => {
    setDialogMessage(getDialogMessage(dialogState))
  }, [dialogState])

  const getDialogMessage = (state: string) => {
    let message = ""
    if (state === "openCaptchaFailed") {
      message =
        "ReCAPTCHA (Security Validation) verification was unsuccessful. Please try again."
    } else if (state === "openNoCaptcha") {
      message =
        "Please complete the ReCAPTCHA (Security Validation) to verify that you are not a bot before sending the message."
    } else if (state === "openWaiting") {
      message = "Busy subscribing. Please wait."
    } else if (state === "openSuccess") {
      message =
        "Hey! Your subscription to our newsletter was successful. Please find a confirmation email in your inbox."
    } else if (state === "openFailed") {
      message =
        "Something went wrong while trying to send your message, please try again later."
    } else if (state === "openAlreadySubscribed") {
      message = "This email is already subscribed to our newsletter."
    }
    return message
  }

  const onCaptchaChange = (token: string | null) => {
    setCaptchaToken(token)
    if (token !== null) verifyReCAPTCHA(token)
  }

  const verifyReCAPTCHA = (token: string) => {
    const data = { token }

    if (api) {
      trackPromise(
        api?.verifyReCaptchaApi
          .execute(data)
          .then(response => {
            if (!response.data.success) {
              setCaptchaToken(null)
              recaptchaRef.current.reset()
              setDialogState("openCaptchaFailed")
              handleSubscribeModalClose()
            }
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
            } else {
              // Something happened in setting up the request that triggered an Error
            }
            setCaptchaToken(null)
            recaptchaRef.current.reset()
            setDialogState("openCaptchaFailed")
            handleSubscribeModalClose()
          })
      )
    }
  }

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (promiseInProgress) return

    if (captchaToken === null) {
      setDialogState("openNoCaptcha")
      handleSubscribeModalClose()
      return
    }

    handleSubscribeModalClose()
    setDialogState("openWaiting")

    // Send a POST request
    trackPromise(
      addToMailchimp(emailAddress)
        .then((data: any) => {
          if (data.result === "success") {
            tagManager?.onFormSubmissionEvent({
              lead_type: "newsletter_subscription",
            })
            setDialogState("openSuccess")
          } else {
            if (data.msg.includes("already subscribed")) {
              setDialogState("openAlreadySubscribed")
            } else {
              // Something went wrong.
              setDialogState("openFailed")
            }
          }
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
        })
    )
  }

  return (
    <div>
      <form autoComplete="on" onSubmit={submitForm}>
        <div className="mb-5">
          <div className="group mb-5">
            <input
              type="email"
              id="subscribe-form-email-address"
              required
              data-value={emailAddress}
              value={emailAddress}
              onChange={e => {
                setEmailAddress(e.target.value)
                e.target.setCustomValidity("")
                if (e.target.validity.valid) {
                  e.target.setCustomValidity("")
                } else {
                  e.target.setCustomValidity(
                    "Please enter a valid email address."
                  )
                }
              }}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label htmlFor="subscribe-form-email-address">
              Email Address *
            </label>
          </div>
          <div className="mb-5">
            <p className="security-input">Security Validation *</p>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY ?? ""}
              onChange={onCaptchaChange}
            />
          </div>
          <div className="mb-5">
            <p className="overline">
              iPhupha Digital is committed to protecting your information. Your
              information will be used in accordance with the applicable data
              privacy law, our internal policies and our{" "}
              <Link className="text-tertiary text-hover-black" to="/privacy">
                privacy policy
              </Link>
              . As iPhupha Digital is a global company, your information may be
              stored and processed by iPhupha Digital and its affiliates in
              countries outside your country of residence, but wherever your
              information is processed, we will handle it with the same care and
              respect for your privacy.
            </p>
          </div>
          <div className="mb-5">
            <ElevatedButton label="Subscribe" />
          </div>
        </div>
      </form>

      <Modal
        open={open}
        title="Subscribe To Newsletter"
        handleClose={handleSubscribeModalClose}
      >
        <p>{dialogMessage}</p>
      </Modal>
    </div>
  )
}

export default SubscribeForm
